<template>
  <v-main class="pa-0 padding-adjustment">
    <!-- <layout-drawer /> -->

    <v-responsive class="mx-auto overflow-visible">
      <v-container fluid class="pa-0">
        <v-responsive class="overflow-visible" min-height="90vh">
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-responsive>
      </v-container>
    </v-responsive>
  </v-main>
</template>

<script>
export default {
  name: 'LayoutView',
};
</script>

<style lang="scss" scoped>
.padding-adjustment {
  padding-top: 125px !important;
  padding-right: 0px !important;
}

@media screen and (max-width: 600px) {
  .padding-adjustment {
    padding-top: 125px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
